import { useEffect, useReducer } from 'react';
import { initialNextState } from './Next.state';
import { nextReducer } from './Next.reducer';
import { fetchNext } from '../../http-services/Parts';
import { getImagePath } from '../../helpers/Image';
import { AddCurrency } from '../../atoms/Currency';
import { Loading } from '../../atoms/Loading';

function LoadNext(props) {
  const { loading, partId, color, total } = props

  if (loading) {
    return (<><Loading/></>)
  }

  if (!partId || !color) {
    return (
      <>
        <p>Searching for next part</p>
      </>
    )
  }

  return (
    <>
      <img src={getImagePath(partId, color)} alt="this will be the next part" /><br/>
      <strong><AddCurrency value={total} /></strong> required
    </>
  )
}

function Next() {
  const [next, dispatch] = useReducer(nextReducer, initialNextState)

  useEffect(() => {
    const getNext = async () => {
      const nextFromServer = await fetchNext()
      dispatch({type: 'loadNextSuccess', next: nextFromServer})
    }
    getNext()
  }, [])

  return (
    <div className="next-container">
      <h2>Next item</h2>
      <LoadNext loading={next.loading} partId={next.partId} color={next.color} total={next.total} />
    </div>
  )
}

export default Next;
