import './Header.css'

function Header() {
    return (
        <div className="header">
            <img src="images/cl-100.jpg" alt="#communityLambo" />
            <span className="header-text">#communityLambo</span>
        </div>
    )
}

export default Header;
