import React, { useEffect, useReducer } from 'react';
import { stagesReducer } from './Stages.reducer';
import { initialStagesState } from './Stages.state';
import { getStages } from '../../http-services/Stages';
import { Loading } from '../../atoms/Loading';
import Collapsible from 'react-collapsible';

import './CollapsibleSteps.css'
import Steps from './Steps';
import { stepsReducer } from './Steps.reducer';
import { initialStepsState } from './Steps.state';
import { getSteps } from '../../http-services/Steps';

function Trigger({ stage }) {
  const img = `./images/group-${stage.id}.png`

  return <div className="trigger-container">
    <div className="trigger-image"><img src={img} alt="Group 1" height="200"/></div>
    <div className="trigger-text">
      <h2>Group {stage.id}</h2>
      <p>Steps {stage.from} to {stage.to}</p>
      <p>{stage.name}</p>
      <p className="cta">Click Box to open steps</p>
    </div>
    <div className="clear"></div>
  </div>
}

function LoadStages(props) {
  const { stageState, stepsState } = props
  if (stageState.loading || stepsState.loading) {
    return (<><Loading/></>)
  }

  return (
    <>
      {stageState.stages.map((stage) => (
        <Collapsible trigger={Trigger({ stage })} key={stage.id} open={stage.id === stageState.stageOpen}>
          <Steps steps={stepsState.steps[stage.id]}/>
        </Collapsible>
      ))}
    </>
  )
}

function CollapsibleSteps() {
  const [stageState, dispatchStages] = useReducer(stagesReducer, initialStagesState)
  const [stepsState, dispatchSteps] = useReducer(stepsReducer, initialStepsState)

  useEffect(() => {
    getStages(dispatchStages)
    getSteps(dispatchSteps)
  }, [])

  return (
    <div className="stepsContainer">
      <div className="innerContainer">
        <h2>Steps</h2>
        <p>This are not all steps yet - new steps will be added while the project continues</p>
        <LoadStages stageState={stageState} stepsState={stepsState}/>
      </div>
    </div>
  )
}

export default CollapsibleSteps
