import './Youtube.css'

function Youtube() {

    return(
        <div className="youtube-container">
            <iframe src="https://www.youtube.com/embed/4LST7b2FLfo"
                    title="YouTube video player" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>
        </div>
    )

}

export default Youtube;
