import {apiUrl} from "../helpers/Environment";

export const fetchMetrics = async () => {
    const res = await fetch(`${apiUrl()}?c=metrics&a=metrics`)
    return await res.json()
}

export const fetchStats = async () => {
    const res = await fetch(`${apiUrl()}?c=metrics&a=stats`)
    return await res.json()
}

export const triggerApiUpdate = async () => {
    const res = await fetch(`${apiUrl()}?c=update&a=metrics`)
    return await res.json()
}

export const getRanking = async (dispatch, isMobile) => {
    const ranking = await fetchRanking(isMobile)
    dispatch({'type': 'rankingLoadSuccess', ranking})
}

const fetchRanking = async (isMobile) => {
    const res = await fetch(`${apiUrl()}?c=metrics&a=ranking&isMobile=${isMobile}`)
    return await res.json()
}
