import './PartTable.css'
import {fetchParts} from "../http-services/Parts";
import {useEffect, useState} from "react";
import {AddCurrency} from "../atoms/Currency";
import {GetTotal} from "../atoms/GetTotal";
import {isProd} from "../helpers/Environment";
import Modal from 'react-modal';
import {addStep} from "../http-services/Steps";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const PartImageTag = (props) => {
    const {partId, color} = props
    return (<img alt={partId} src={'images/parts/' + partId + '-' + color.replace(/\s/g, '') + '.jpg'}/>)
}

function PartTable() {
    const [parts, setParts] = useState({parts: [], total: {required: '', bought: '', total: ''}});
    const [modalIsOpen, setIsOpen] = useState(false);
    const [currentPart, setCurrentPart] = useState(null)
    const [currentColor, setCurrentColor] = useState(null)


    useEffect(() => {
        const getParts = async () => {
            const partsFromServer = await fetchParts()
            setParts(partsFromServer)
        }

        getParts()
    }, [])

    function openModal(partId, color) {
        setIsOpen(true)
        setCurrentPart(partId)
        setCurrentColor(color)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        addStep(currentPart, e.target[0].value, e.target[1].value).then(() => closeModal())
    }

    function closeModal() {
        setIsOpen(false)
    }

    function PartImage(props) {
        const {partId, isProd, color} = props

        if (isProd) {
            return (<PartImageTag partId={partId} color={color} />)
        }

        return (
            <>
                <img alt={partId} src={'images/parts/' + partId + '-' + color.replace(/\s/g, '') + '.jpg'} onClick={() => openModal(partId, color)}/>
                <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Add Step Modal" ariaHideApp={false}>
                    <h2>Add Step</h2>
                    <button onClick={closeModal}>close</button>
                    <form onSubmit={handleSubmit} >
                        <div>
                            <PartImageTag partId={currentPart} color={currentColor} />
                        </div>
                        <div>
                            <input type="text" name="step" placeholder="Step" autoComplete="off" />
                        </div>
                        <div>
                            <input type="text" name="count" placeholder="Count" autoComplete="off" />
                        </div>
                        <div>
                            <input type="submit" name="submit" value="Add" />
                        </div>
                    </form>
                </Modal>
            </>
        )

    }

    return (
        <div className="partsContainer">
            <div className="innerContainer">
                <h2>Parts</h2>
                <p>Those parts have been added already</p>
                <table>
                    <thead>
                    <tr className="table-header">
                        <td>&nbsp;</td>
                        <td>Nr</td>
                        <td>Required</td>
                        <td>Added</td>
                        <td>Price</td>
                        <td>Total</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr className="total">
                        <td>TOTAL</td>
                        <td>&nbsp;</td>
                        <td className="align-right">{parts.total.required}</td>
                        <td className="align-right">{parts.total.bought}</td>
                        <td>&nbsp;</td>
                        <td className="align-right"><AddCurrency value={parts.total.total}/></td>
                    </tr>
                    {parts.parts.map((part) => (
                        <tr key={part.id} className="part-row">
                            <td className="image"><PartImage partId={part.partId} color={part.color} isProd={isProd()} /></td>
                            <td className="align-right">{part.partId}</td>
                            <td className="align-right">{part.requiredNr}</td>
                            <td className="align-right">{part.bought}</td>
                            <td className="align-right"><AddCurrency value={part.price}/></td>
                            <td className="align-right"><GetTotal price={part.price} bought={part.bought}/></td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default PartTable;
