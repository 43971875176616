import './Imprint.css'

function Imprint() {

    return(
        <div className="imprint-container">
            <hr/>
            <p>Impressum / Imprint</p>
            <p>Carsten Ennulat<br/>Beim Greimel 3<br/>85652 Pliening<br/>Germany</p>
            <p>E_mail: klemmbau@team-ice.de<br/>Tel: +49 1525 373 16 83</p>
        </div>
    )

}

export default Imprint
