export const apiUrl = () => {
    let url = '';
    switch(process.env.NODE_ENV) {
        case 'production':
            url = '/yt-be/';
            break;
        case 'development':
        default:
            url = 'http://localhost:8500/yt-be/';
    }

    return url;
}

export const isProd = () => {
    return process.env.NODE_ENV === 'production'
}
