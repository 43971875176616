export function AddCurrency(props) {
    if (!props.value) {
        return ''
    }

    const precision = (props.precision === undefined) ? 2 : props.precision
    const calc = Math.pow(10, precision)

    const num = (Math.round(props.value * calc) / calc).toFixed(precision)

    return `${num}€`
}
