export const revenueReducer = (state, action) => {
    switch (action.type) {
        case 'loadMetrics':
            return {...state, loading: true}
        case 'loadSuccess':
            return action.metrics
        case 'update':
            const {payload} = action

            if (payload.error) {
                return {...state, loading: false, error: payload.error, url: payload.info}
            }

            return {...state, loading: false, lastUpdate: action.payload.updated}
        default:
            throw new Error()
    }
}
