import { useEffect, useReducer } from 'react';
import { initialRankingState } from './Ranking.state';
import { Loading } from '../../atoms/Loading';
import { rankingReducer } from './Ranking.reducer';
import { getRanking } from '../../http-services/Metrics';
import { Bar, BarChart, CartesianGrid, LabelList, Legend, ResponsiveContainer, Tooltip, YAxis } from 'recharts';
import { isMobile } from 'react-device-detect';

const renderCustomizedLabel = (props) => {
  const { x, y, width, value } = props;

  return (
    <g>
      <foreignObject x={x-1} y={y-width/1.4} width={width+2} height={100}>
        <a href={`https://www.youtube.com/watch?v=${value}`} target="_blank" rel="noreferrer">
          <img src={`./images/thumbs/${value}.jpg`} width={width} border={1} alt={value} />
        </a>
      </foreignObject>
    </g>
  );
};

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip"><strong>Revenue:</strong> {payload[0].value}€</div>
    );
  }

  return null;
};

const sortAndSliceData = (rows) => {
  return rows
    .sort((a, b) => b.revenue - a.revenue)
    .map(row => ({...row, revenue: +row.revenue}))
    .slice(0, 10)
}

function Ranking() {
  const [rankingState, dispatchRanking] = useReducer(rankingReducer, initialRankingState)

  useEffect(() => {
    getRanking(dispatchRanking, isMobile)
  }, [])

  if (rankingState.loading) {
    return (<div className="loadingCenter"><h2>Video Rankings</h2><Loading /></div>)
  }

  const data = sortAndSliceData([...rankingState.ranking])
  const nr = isMobile ? 3 : 10

  return (
    <div className="ranking-container">
      <h2>Top {nr} Video Rankings</h2>

      <div className="chart-container">
        <ResponsiveContainer width="95%" height={400}>
          <BarChart
            width={800}
            height={400}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <YAxis />
            <Legend />
            <Tooltip content={<CustomTooltip />} />

            <Bar dataKey="revenue" fill="#282c34" minPointSize={5}>
              <LabelList dataKey="videoId" content={renderCustomizedLabel}  />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default Ranking
