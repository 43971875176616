import './App.css';
import PartTable from "./components/PartTable";
import Header from "./components/Header";
import Revenue from "./components/Revenue/Revenue";
import Youtube from "./components/Youtube";
import Imprint from "./components/Imprint";
import Stats from "./components/Stats";
import Next from './components/Next/Next';
import CollapsibleSteps from './components/Steps/CollapsibleSteps';
import Ranking from './components/Ranking/Ranking';

function App() {
  return (
    <div className="App">
        <Header/>
        <Youtube/>
        <Revenue/>
        <Next/>
        <Ranking/>
        <div className="container">
            <CollapsibleSteps />
            <PartTable />
        </div>
        <Stats/>
        <Imprint/>
    </div>
  );
}

export default App;
