import './Stats.css'
import {useEffect, useState} from "react";
import {fetchStats} from "../http-services/Metrics";
import {AddCurrency} from "../atoms/Currency";

function Stats() {
    const [stats, setStats] = useState(null)

    useEffect(() => {
        const getStats = async () => {
            const statsFromServer = await fetchStats()
            setStats(statsFromServer)
        }

        getStats()
    }, [])

    function StatRow(props) {
        const {name, value} = props
        return (<div className="stat-row"><span className="stat-value">{value}</span><br/><span className="stat">{name}</span> </div>)
    }

    function StatsOrLoading(props) {
        const {stats} = props

        if (!props.stats) {
            return (<>Loading</>)
        }

        return (
            <>
                <StatRow name="Days passed" value={stats.daysPassed} />
                <StatRow name="Revenue per Day" value={AddCurrency({value: stats.revenueParDay})} />
                <StatRow name="Days left" value={stats.daysLeft} />
                <StatRow name="Time left" value={stats.timeLeft} />
                <StatRow name="Video count" value={stats.videoCount} />
                <StatRow name="Total views" value={stats.views} />
                <StatRow name="Revenue per Video" value={AddCurrency({value: stats.revenuePerVideo})} />
                <StatRow name="Videos left" value={stats.videosLeft} />
                <StatRow name="Parts required" value={stats.partsRequired} />
                <StatRow name="Parts in inventory" value={stats.partsInventory} />
                <StatRow name="already bought" value={`${stats.partsPercent}%`} />
                <StatRow name="Parts used" value={stats.partsBuild} />
                <StatRow name="already build" value={`${stats.buildInPercent}%`} />
                <StatRow name="Subscribed!" value={stats.subscribed} />
            </>
        )
    }

    return (
        <div className="stats-container">
            <h3>🥜 Stats for nuts 🐿️</h3>
            <p>A set of different stats and calculations for #communityLambo</p>
            <StatsOrLoading stats={stats} />
        </div>
    )
}

export default Stats;
