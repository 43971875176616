import './Revenue.css'
import { useEffect, useReducer } from 'react';
import { fetchMetrics, triggerApiUpdate } from '../../http-services/Metrics';
import { AddCurrency } from '../../atoms/Currency';
import { revenueReducer } from './Revenue.reducer';
import { initialRevenueState } from './Revenue.state';

function Revenue() {
    const [metrics, dispatch] = useReducer(revenueReducer, initialRevenueState);

    useEffect(() => {
        const getMetrics = async () => {
            const metricsFromServer = await fetchMetrics()
            dispatch({type: 'loadSuccess', metrics: metricsFromServer})
        }
        getMetrics()
    }, [])



    const updateMetrics = async () => {
        dispatch({type: 'loadMetrics'})
        const update = await triggerApiUpdate()
        dispatch({type: 'update', payload: update})
    }

    function LastUpdate(props) {
        const {lastUpdate, loading, error, url} = props.metrics

        if (url && error) {
            return (<><a href={url}>{error}</a></>)
        }

        if (!lastUpdate || loading) {
            return (<>Loading</>)
        }

        return (<span onClick={async  () => {await updateMetrics()}}>last updated on {lastUpdate}</span>)
    }

    return (
        <div>
            <div className="revenue-container">
                <div className="item">Revenue: <AddCurrency value={metrics.revenue} precision={3} /></div>
                <div className="item">Videos: {metrics.videoCount}</div>
            </div>
            <div className="last-update"><LastUpdate metrics={metrics} /></div>
        </div>
    )
}

export default Revenue;
