import {apiUrl} from "../helpers/Environment";

export const getSteps = async (dispatch) => {
    const steps = await fetchSteps()
    dispatch({type: 'stepsLoadSuccess', steps})
}

export const fetchSteps = async () => {
    const res = await fetch(`${apiUrl()}?c=steps&a=steps`)
    return await res.json()
}

export const addStep = async (partId, stepId, count) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ partId, stepId, count })
    };
    await fetch(`${apiUrl()}?c=steps&a=add`, requestOptions);
}
