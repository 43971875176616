import './Steps.css'
import React from 'react';
import { AddCurrency } from '../../atoms/Currency';
import { GetTotal } from '../../atoms/GetTotal';

function StepNr(props) {
  const {count, index, step} = props
  if (index > 0) {
    return null
  }

  return (<td className="step" rowSpan={count}>{step}</td>)
}

function StepPartImg(props) {
  let file = props.partId;

  if (props.color) {
    const color = props.color.replace(/\s/g, '');
    file =  `${file}-${color}`;
  }

  return (<img alt={file} className={props.bought > 0 ? 'available' : 'missing'} src={'images/parts/' + file + '.jpg'}/>);
}

function Steps(props) {
  const {steps} = props

  return (
    <table>
      <thead>
      <tr className="table-header">
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>Part Nr</td>
        <td>Count</td>
        <td>Price</td>
        <td>Total</td>
      </tr>
      </thead>
      <tbody>
      {Object.keys(steps).map((key) => {
        const partCount = steps[key].length
        let totalPrice = 0;

        const stepsHtml = steps[key].map((part, index) => {
          if (+part.bought > 0) {
            totalPrice += +part.price * +part.bought;
          }

          return (
            <tr key={part.id} className="part-row">
              <StepNr step={key} index={index} count={partCount} />
              <td className="image"><StepPartImg bought={part.bought} color={part.color} partId={part.partId}/></td>
              <td className="align-right">{part.partId}</td>
              <td className="align-right">{part.bought} / {part.count}</td>
              <td className="align-right"><AddCurrency value={part.price} /></td>
              <td className="align-right"><GetTotal price={part.price} bought={part.bought}/></td>
            </tr>
          )
        })


        return (
          <React.Fragment key={key}>
            {stepsHtml}
            <tr className="total" key="total">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td className="align-right"><AddCurrency value={totalPrice} /></td>
            </tr>
          </React.Fragment>
        )
      })}
      </tbody>
    </table>
  )
}

export default Steps
