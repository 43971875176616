import { apiUrl } from '../helpers/Environment';

export const getStages = async (dispatch) => {
  const stages = await fetchStages()
  dispatch({type: 'stagesLoadSuccess', stages})
}

export const fetchStages = async () => {
  const res = await fetch(`${apiUrl()}?c=steps&a=stages`)
  return await res.json()
}
