import {apiUrl} from "../helpers/Environment";

export const fetchParts = async () => {
    const res = await fetch(`${apiUrl()}?c=parts&a=parts`)
    return await res.json()
}

export const fetchNext = async () => {
    const res = await fetch(`${apiUrl()}?c=parts&a=next`)
    return await res.json()
}
